
import MainPage from '../pages/main.vue';
import LoginPage from '../pages/login.vue';
import ForgotPasswordPage from '../pages/forgotpassword.vue';
import SignUpPage from '../pages/signup.vue';
import HomePage from '../pages/home.vue';

import FormPage from '../pages/form.vue';
import CatalogPage from '../pages/catalog.vue';
import ProductPage from '../pages/product.vue';
import SettingsPage from '../pages/settings.vue';
import LandPage from '../pages/land.vue';

import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: LandPage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/forgotpassword/',
    component: ForgotPasswordPage,
  },
  {
    path: '/signup/',
    component: SignUpPage,
  },
  {
    path: '/main/',
    component: MainPage,
    tabs: [
      {

        path: '/',
        id: 'home',
        component: HomePage,
      },
      {
        path: '/graveyard/',
        id: 'graveyard',
        component: FormPage,
      },
      {
        path: '/notify/',
        id: 'notify',
        component: CatalogPage,
      },
      {
        path: '/more/',
        id: 'more',
        component: SettingsPage,
      },
    ]
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
