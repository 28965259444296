<template>
  <f7-page name="forgotpassword">
    <f7-page login-screen>
      <span
        class="material-icons"
        style="
          top: 0;
          position: fixed;
          padding-left: 20px;
          padding-top: 20px;
          color: #00c48b;
          font-size: 30px;
        "
        @click="f7router.back()"
      >
        keyboard_arrow_left </span
      ><br />

      <f7-login-screen-title>
        <img src="../assets/img/logo_avatar_sad.png" /><br />
        Forgot Password
      </f7-login-screen-title>
      <f7-block>
        <f7-list id="resetpwform" form>
          <f7-list-input
            type="email"
            name="email"
            placeholder="Email"
            :value="email"
            @input="email = $event.target.value"
            error-message="Please enter email address"
            required
            validate
            clear-button
          >
          </f7-list-input>
        </f7-list>
        <f7-list>
          <f7-button
            id="reset_btn"
            v-bind:class="{ disabled: isDisabled }"
            round
            fill
            style="height: 50px; margin-bottom: 35px"
            @click="resetPassword"
            >Reset Password</f7-button
          >
        </f7-list>
        <f7-sheet
          id="resetmailsent"
          style="height: auto; --f7-sheet-bg-color: #fff"
          swipe-to-close
          backdrop
        >
          <f7-page-content>
            <f7-block-title medium class="text-align-center"
              >Reset Password Instruction Sent!
            </f7-block-title>
            <f7-block>
              <p class="text-align-center">Please Check you Email</p>
              <p class="text-align-center">
                <img
                  src="../assets/img/logo_avatar_sad.png"
                  style="height: 200px"
                />
              </p>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
        <f7-sheet
          id="logininerror"
          style="height: auto; --f7-sheet-bg-color: #fff"
          swipe-to-close
          backdrop
        >
          <f7-page-content>
            <f7-block-title medium class="text-align-center"
              >Email Address Not Found
            </f7-block-title>
            <f7-block>
              <p class="text-align-center">
                Please
                <f7-link
                  sheet-close
                  href="/signup/"
                  style="
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;

                    color: #fbba2c;
                  "
                  >Sign Up</f7-link
                >
              </p>
              <p class="text-align-center">
                <img
                  src="../assets/img/logo_avatar_sad.png"
                  style="height: 200px"
                />
              </p>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
      </f7-block>
    </f7-page>
  </f7-page>
</template>
<script>
import { f7 } from "framework7-vue";

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {
      email: "",
      isDisabled: false,
    };
  },
  methods: {
    resetPassword() {
      const self = this;

      var email, pw;
      email = self.email;
      var checkform = function () {
        var inputs = f7.$("#resetpwform input");
        var checkarr = [];
        for (let i = 0; i < inputs.length; i++) {
          if (!inputs[i].checkValidity()) {
            checkarr.push(i);
          }
        }
        return checkarr.length;
      };
      console.log(checkform());
      if (checkform() == 0) {
        self.isDisabled = true;
        var uObj = {
          data: {
            email: email,
          },
          token: "",
        };
        console.log(uObj);
        f7.request
          .post(
            f7.store.state.config.api + "/email/tempemail/sendResetPassword",
            uObj
          )
          .then(function (res) {
            var o = JSON.parse(res.data);
            if (o.success) {
              f7.sheet.open("#resetmailsent");
              self.isDisabled = false;
            } else {
              f7.sheet.open("#logininerror");
              self.isDisabled = false;
            }
          });
      }
    },
  },
};
</script>