<template>
  <f7-page name="home">
    <f7-page login-screen>
      <f7-navbar style="background: #ffffff !important">
        <f7-nav-left>
          <f7-link>
            <img src="../assets/img/init_logo.png" style="height: 40px"
          /></f7-link>
        </f7-nav-left>
        <f7-nav-title></f7-nav-title>
        <f7-nav-right>
          <f7-link>
            <span class="material-icons"> search </span>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <img src="../assets/img/avatar.png" />
    </f7-page>
  </f7-page>
</template>
<script>
import { f7 } from "framework7-vue";

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {
      username: "",
      password: "",
      cpassword: "",
    };
  },
  methods: {
    signIn() {
      const self = this;
      self.f7router.back();
      //   f7.dialog.alert(
      //     `Username: ${self.username}<br>Password: ${self.password}`,
      //     () => {

      //     }
      //   );
    },
  },
};
</script>