<template>
  <f7-page name="signup">
    <f7-page login-screen>
      <img
        src="../assets/img/init_logo.png"
        style="top: 0; position: fixed; padding-left: 20px; padding-top: 20px"
      /><br />

      <f7-login-screen-title> Sign Up </f7-login-screen-title>
      <f7-block>
        <f7-list id="signupform" form>
          <f7-list-input
            type="email"
            name="email"
            placeholder="Email"
            :value="email"
            @input="email = $event.target.value"
            error-message="Please enter email address"
            required
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Password"
            :value="password"
            @input="password = $event.target.value"
            error-message="Please enter your password"
            required
            validate
            clear-button
          ></f7-list-input>
          <f7-list-input
            type="password"
            name="cpassword"
            placeholder="Confirm Password"
            :value="cpassword"
            @input="cpassword = $event.target.value"
            error-message="Please confirm your password"
            required
            validate
            clear-button
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-button
            v-bind:class="{ disabled: isDisabled }"
            round
            fill
            style="height: 50px; margin-bottom: 35px"
            @click="register"
            >Sign Up</f7-button
          >

          <f7-block-footer>
            Have an account?
            <a
              href="/login/"
              style="
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;

                color: #fbba2c;
              "
              >Login</a
            >
          </f7-block-footer>
        </f7-list>
        <img
          style="position: fixed; right: 0; bottom: 0; z-index: -9"
          src="../assets/img/avatar.png"
        />
        <f7-sheet
          id="emailinuse"
          style="height: auto; --f7-sheet-bg-color: #fff"
          swipe-to-close
          backdrop
        >
          <f7-page-content>
            <f7-block-title medium class="text-align-center"
              >Email Already in Use
            </f7-block-title>
            <f7-block>
              <p class="text-align-center">
                Have an account?
                <f7-link
                  href="/login/"
                  sheet-close
                  style="
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    color: #fbba2c;
                  "
                  >Login</f7-link
                ><br /><br />
                <f7-link
                  sheet-close
                  href="/forgotpassword/"
                  style="
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;

                    color: #fbba2c;
                  "
                  >Forgot password</f7-link
                >
              </p>
              <p class="text-align-center">
                <img
                  src="../assets/img/logo_avatar_sad.png"
                  style="height: 200px"
                />
              </p>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
      </f7-block>
    </f7-page>
  </f7-page>
</template>
<script>
import { f7 } from "framework7-vue";

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {
      email: "",
      password: "",
      cpassword: "",
      isDisabled: false,
    };
  },
  methods: {
    register() {
      const self = this;
      console.log(f7.routes);
      var email, pw, cpw;
      email = self.email;
      pw = self.password;
      cpw = self.cpassword;

      var checkform = function () {
        var inputs = f7.$("#signupform input");
        var checkarr = [];
        for (let i = 0; i < inputs.length; i++) {
          if (!inputs[i].checkValidity()) {
            checkarr.push(i);
          }
        }
        return checkarr.length;
      };

      if (pw == cpw) {
        if (checkform() == 0) {
          self.isDisabled = true;
          var uObj = {
            data: {
              email: email,
              password: pw,
            },
            token: "",
          };

          f7.request
            .post(f7.store.state.config.api + "/account/user/register", uObj)
            .then(function (res) {
              var o = JSON.parse(res.data);
              if (o.success) {
                f7.views.main.router.navigate("/main/");
              } else {
                f7.sheet.open("#emailinuse");
              }
              self.isDisabled = false;
            });
        }
      } else {
        f7.$(".item-input-error-message")[2].innerHTML =
          "Password does not match";
        f7.$("input")[2].value = "";
        f7.$("input")[2].checkValidity();
      }
    },
  },
};
</script>