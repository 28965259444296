<template>
  <f7-page :page-content="false">
    <f7-toolbar tabbar labels bottom>
      <f7-link
        href="./"
        route-tab-id="home"
        tab-link
        tab-link-active
        icon-ios="f7:house_fill"
        icon-aurora="f7:house_fill"
        icon-md="material:home"
        text="Home"
      ></f7-link>
      <f7-link
        href="./graveyard/"
        route-tab-id="graveyard"
        tab-link
        icon-ios="f7:person_2"
        icon-aurora="f7:person_2"
        icon-md="material:group"
        text="Graveyard"
      ></f7-link>
      <f7-link
        href="./notify/"
        route-tab-id="notify"
        tab-link
        icon-ios="f7:bell"
        icon-aurora="f7:bell"
        icon-md="material:notifications_none"
        text="Notification"
      ></f7-link>
      <f7-link
        href="./more/"
        route-tab-id="more"
        tab-link
        icon-ios="f7:menu"
        icon-aurora="f7:menu"
        icon-md="material:menu"
        text="Notification"
      ></f7-link>
    </f7-toolbar>
    <f7-tabs animated tabs-routable>
      <f7-tab id="home" class="page-content" tab-active></f7-tab>
      <f7-tab id="graveyard" class="page-content"></f7-tab>
      <f7-tab id="notify" class="page-content"></f7-tab>
      <f7-tab id="more" class="page-content"></f7-tab>
    </f7-tabs>
  </f7-page>
</template>