<template>
  <f7-page name="login">
    <f7-page login-screen>
      <img
        src="../assets/img/init_logo.png"
        style="top: 0; position: fixed; padding-left: 20px; padding-top: 20px"
      /><br />

      <f7-login-screen-title>
        <img src="../assets/img/logo_avatar.png" /><br />
        Login
      </f7-login-screen-title>
      <f7-block>
        <f7-list id="loginform" form>
          <f7-list-input
            type="email"
            name="email"
            placeholder="Email"
            :value="email"
            @input="email = $event.target.value"
            error-message="Please enter email address"
            required
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Password"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-button
            v-bind:class="{ disabled: isDisabled }"
            round
            fill
            style="height: 50px; margin-bottom: 35px"
            @click="signIn"
            >Login</f7-button
          >

          <f7-block-footer>
            Don’t have an account?
            <a
              href="/signup/"
              style="
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;

                color: #fbba2c;
              "
              >Sign Up</a
            >
            <br />
            <br />
            <a
              href="/forgotpassword/"
              style="
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;

                color: #fbba2c;
              "
              >Forgot password</a
            >
          </f7-block-footer>
        </f7-list>
        <f7-sheet
          id="logininerror"
          style="height: auto; --f7-sheet-bg-color: #fff"
          swipe-to-close
          backdrop
        >
          <f7-page-content>
            <f7-block-title medium class="text-align-center"
              >Incorrect Account Information
            </f7-block-title>
            <f7-block>
              <p class="text-align-center">
                <f7-link
                  sheet-close
                  href="/forgotpassword/"
                  style="
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;

                    color: #fbba2c;
                  "
                  >Forgot password ?</f7-link
                >
              </p>
              <p class="text-align-center">
                <img
                  src="../assets/img/logo_avatar_sad.png"
                  style="height: 200px"
                />
              </p>
            </f7-block>
          </f7-page-content>
        </f7-sheet>
      </f7-block>
    </f7-page>
  </f7-page>
</template>
<script>
import { f7 } from "framework7-vue";

export default {
  props: {
    f7router: Object,
  },
  data() {
    return {
      email: "",
      password: "",
      isDisabled: false,
    };
  },
  methods: {
    signIn() {
      const self = this;

      var email, pw;
      email = self.email;
      pw = self.password;
      var checkform = function () {
        var inputs = f7.$("#loginform input");
        var checkarr = [];
        for (let i = 0; i < inputs.length; i++) {
          if (!inputs[i].checkValidity()) {
            checkarr.push(i);
          }
        }
        return checkarr.length;
      };

      if (checkform() == 0) {
        self.isDisabled = true;
        var uObj = {
          data: {
            un: email,
            pw: pw,
          },
          token: "",
        };

        f7.request
          .post(f7.store.state.config.api + "/account/user/login", uObj)
          .then(function (res) {
            var o = JSON.parse(res.data);
            if (o.success) {
              f7.views.main.router.navigate("/main/");
            } else {
              f7.sheet.open("#logininerror");
            }
            self.isDisabled = false;
          });
      }
    },
  },
};
</script>