<template>
  <f7-page name="land">
    <f7-block>
      <f7-row>
        <f7-col
          width="100"
          style="
            font-weight: normal;
            font-size: 30px;
            color: #00c48b;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            text-align: center;
          "
          ><img src="../assets/img/init_logo.png" /><br />It's not over</f7-col
        >
      </f7-row>
      <f7-row>
        <f7-col width="100">
          <f7-button
            href="/signup/"
            round
            fill
            color="white"
            style="
              height: 50px;
              color: #00c48b;
              margin-bottom: 35px;
              margin-top: 100px;
              border: 1px solid #00c48b;
            "
            >Sign Up</f7-button
          >
          <f7-button
            href="/login/"
            round
            fill
            style="height: 50px; margin-bottom: 35px"
            >Login</f7-button
          >
          <f7-button round fill style="height: 50px; background: #fbba2c"
            >Guest</f7-button
          >
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="100"
          ><img
            style="position: fixed; left: 0; bottom: 0; z-index: -9"
            src="../assets/img/init_bg.png"
        /></f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
